import styled from "styled-components";
import { breakpoints } from "utils/mixins";
import { p, p_alternative } from "../../theme/typography";

export const Wrapper = styled.div`
  width: 100vw;
  position: relative;
  transition: 400ms;
  background-color: ${(props) => props.bg};
  .react-parallax {
    top: ${(props) => `${props.index * 100}vh`};
    height: 100vh;
    position: relative !important;
    overflow: visible !important;
  }
  .slide-up {
    z-index: 10;
    position: absolute;
    height: 35vh;
    width: 90%;
    left: 5%;
    top: 5%;
    cursor: url(images/up.png), auto;
  }
  .slide-down {
    z-index: 10;
    position: absolute;
    height: 35vh;
    width: 90%;
    left: 5%;
    bottom: 5%;
    cursor: url(images/down.png), auto;
  }
  @media ${breakpoints.laptop} {
    height: ${(props) => `${props.height * 100}vh`};
    overflow: hidden;
  }
`;

export const Card = styled.div`
  width: 100vw;
  height: auto;
  transition: 400ms;
  background: ${(props) => props.color};
  position: relative;
  top: 0;
  left: 0;
  &:last-of-type {
    .card-text {
      padding-bottom: 50px;
    }
  }
  &.fixed {
    top: 0;
    left: 0;
    position: fixed !important;
    transform: translateY(0);
  }
  h2 {
    ${p_alternative}
    color: white;
    text-align: left;
    margin: 0 20px;
  }
  img {
    display: block;
    width: 100vw;
    height: 100%;
    object-fit: contain;
    opacity: 1;
  }
  .card-text {
    position: relative;
    padding-bottom: 20px;
    p {
      margin: 0;
      margin-bottom: 20px;
    }
    h1 {
      ${p}
      display: inline;
      font-weight: bold;
      color: white;
      font-size: 28px;
      line-height: 32px;

      text-align: left;
      text-transform: unset;
      margin: 0;
    }
  }
  @media ${breakpoints.desktop} {
    transition: unset;
    height: 100vh;
    max-height: 100vh;
    transform: ${(props) => `translateY(${props.height * 100}vh)`};
    z-index: ${(props) => props.height};
    position: absolute;
    img {
      min-height: 100vh;
      max-height: 100vh;
      width: 100vw;
      object-fit: cover;
    }
    .card-text {
      position: absolute;
      max-width: 377px;
      bottom: 200px;
      padding: 0;
    }
  }
`;
