import styled from "styled-components";
import { p_alternative } from "theme/typography";
import { toRem, breakpoints } from "utils/mixins";
import { green } from "theme/colors";

export const Wrapper = styled.div`
  background-color: ${green.default};
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  z-index: 20;
  video {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  img {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .scroll {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    padding: ${toRem(25)};
    bottom: 0;
    cursor: url(images/down.png), auto;
    span {
      display: block;
      letter-spacing: ${toRem(1.2)};
      text-transform: uppercase;
      color: white;
      font-size: ${toRem(16)};
      line-height: ${toRem(16)};
      margin-bottom: ${toRem(15)};
      cursor: url(images/down.png), auto;
      ${p_alternative}
    }
    svg {
      display: block;
      height: ${toRem(22)};
      margin-left: 50%;
    }
  }
  @media ${breakpoints.laptop} {
    .scroll {
      left: 50%;
      width: auto;
      padding: ${toRem(50)};
      transform: translateX(-50%);
      bottom: ${toRem(20)};
    }
  }
`;

export const Controls = styled.div`
  position: absolute;
  right: ${toRem(20)};
  top: 50%;
  transform: translateY(-50%);
  button {
    position: relative;
    display: block;
    margin-bottom: ${toRem(8)};
    padding: ${toRem(4)};
    height: ${toRem(28)};
    width: ${toRem(28)};
  }
  svg {
    position: absolute;
    left: ${toRem(6)};
    top: ${toRem(6)};
    display: block;
    height: ${toRem(16)};
  }
  @media ${breakpoints.laptop} {
    right: ${toRem(64)};
  }
`;
