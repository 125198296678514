import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import ReactPlayer from "react-player";

import { Wrapper, Controls } from "./FCard.styles";

import Events from "./events";
import { Arrow, Audio, Mute, Pause, Play } from "../icons";

const FCard = (props) => {
  //PROPS
  const { data, events, image, dimensions } = props;
  //PROPS

  //STATES
  const [video, setVideo] = useState({
    playing: true,
    controls: true,
    volume: 0.2,
    muted: true,
    duration: 0,
    autoplay: false,
    withSound: false,
    played: 0,
    url: data?.mediaItemUrl,
    ready: false
  });
  //STATES

  //REF
  const thePlayer = useRef(null);
  //REF

  //FUNCS
  const videoHandler = {
    handlePlayPause: (e) => {
      setVideo({ ...video, playing: e });
    },
    handleDuration: (duration) => {
      setVideo({ ...video, duration: duration });
    },
    handleSound: (e) => {
      if (!video.withSound) {
        setVideo({ ...video, muted: e, withSound: true });
        thePlayer.current.seekTo(0);
      } else {
        setVideo({ ...video, muted: e });
      }
    }
  };
  //FUNCS

  //EFFECTS
  useEffect(() => {
    setVideo({
      ...video,
      playing: true
    });
  }, []);
  //EFFECTS

  return (
    <Wrapper>
      <ReactPlayer
        className="react-player"
        url={
          dimensions.width >= 769
            ? "/videos/video_desktopb.mp4"
            : dimensions.width >= 450
            ? "/videos/video_tabletb.mp4"
            : "/videos/video_mobileb.mp4"
        }
        width="100%"
        height="100%"
        //
        onReady={() => setVideo({ ...video, ready: true })}
        onPause={() => setVideo({ ...video, playing: false })}
        //
        loop={true}
        controls={false}
        playing={video.playing}
        light={video.light}
        muted={video.muted}
        ref={thePlayer}
      />

      <Controls>
        <button
          onClick={() => videoHandler.handlePlayPause(!video.playing)}
          aria-label="Variar entre play e pause"
        >
          {video.playing ? <Pause /> : <Play />}
        </button>
        <button
          onClick={() => videoHandler.handleSound(!video.muted)}
          aria-label="Variar entre som e mutado"
        >
          {video.muted ? <Mute /> : <Audio />}
        </button>
      </Controls>

      <Events events={events} />

      <Link
        className="scroll"
        to="card"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <span>Conhece a banda</span>
        <Arrow />
      </Link>
    </Wrapper>
  );
};

export default FCard;
