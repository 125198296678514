import React, { useEffect, useState } from "react";

import { InView } from "react-intersection-observer";
import HTMLReactParser from "html-react-parser";

import { Wrapper, Card } from "./TeamCards.styles";
import Dots from "./dots";
import useScrollDirection from "utils/scrollDir";
import Scroll from "react-scroll";

const TeamCards = (props) => {
  //PROPS
  const { data, dimensions } = props;
  //PROPS

  const scroll = Scroll.ScrollElement;
  //STATES
  const [slide, setSlide] = useState(0);
  const [bg, setBG] = useState("#db6c23");
  const [dir, setDir] = useState(false);
  const [forceReload, setReload] = useState(false);
  //STATES

  //FUNCS
  const updateSlide = (index) => {
    scroll.scrollTo(`#id${index}`);
  };

  const handleDir = (e) => {
    if (e !== dir) {
      setDir(e);
    }
  };

  useScrollDirection({ thresholdPixels: 50 })
    ? handleDir(true)
    : handleDir(false);

  //FUNCS

  //EFFECTS
  const handleScroll = () => {
    let indexs = [2, 3, 4, 5];

    if (dimensions.width > 1024) {
      indexs.forEach((e) => {
        if (window.innerHeight + window.scrollY >= window.innerHeight * e) {
          document.querySelector(`.paralax${e - 2}`).classList.add("fixed");
        } else if (
          window.innerHeight + window.scrollY <
          window.innerHeight * e
        ) {
          document.querySelector(`.paralax${e - 2}`).classList.remove("fixed");
        }
      });

      indexs.forEach((e) => {
        let it = e - 0.5;
        let it2 = e + 0.45;
        if (
          window.innerHeight + window.scrollY >= window.innerHeight * it &&
          window.innerHeight + window.scrollY < window.innerHeight * it2
        ) {
          setSlide(e - 2);
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dimensions]);

  useEffect(() => {
    setReload(!forceReload);
    if (dimensions.width <= 1024) {
      document
        .querySelectorAll(`.card`)
        .forEach((e) => e.classList.remove("fixed"));
    }
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper id="card" height={data.length} bg={bg}>
      <Dots slide={slide} setSlide={updateSlide} data={data}></Dots>

      {data.map((elem, index) => {
        let image = elem.image?.localFile?.childImageSharp?.fluid;
        let imageM = elem.mobileImage?.localFile?.childImageSharp?.fluid;
        // console.log(elem);
        return (
          <Card
            className={`card paralax${index}`}
            data-color={bg}
            data-index={index}
            key={index}
            height={index}
          >
            <InView
              as="div"
              onChange={(inView, entry) => {
                if (inView) {
                  setBG(elem.backgroundColor);
                }
              }}
              threshold={0.4}
            >
              {dimensions.width <= 1024 && (
                <>
                  <img
                    src={imageM.src}
                    srcSet={imageM.srcSet}
                    alt={elem.mobileImage.altText}
                  />

                  <h2>{elem.title}</h2>
                </>
              )}
              {dimensions.width > 1024 && (
                <img
                  src={image.src}
                  srcSet={image.srcSet}
                  alt={elem.image.altText}
                />
              )}
              <div className="container">
                <div className="card-text">
                  {elem.text && HTMLReactParser(elem.text)}
                </div>
              </div>
            </InView>
          </Card>
        );
      })}
    </Wrapper>
  );
};

export default TeamCards;
