import styled from "styled-components";
import { breakpoints } from "utils/mixins";

export const Wrapper = styled.ul`
  position: fixed;
  right: 50px;
  z-index: 19;
  display: none;
  top: 50vh;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 0;
    a {
      display: block;
      padding: 8px;
      margin: 0;
      position: relative;
      p {
        position: absolute;
        margin: 0;
        left: 0;
        top: 50%;
        transform: translateX(-90%) translateY(-55%);
        width: auto;
        display: block;
        width: 150px;
        padding-right: 10px;
        text-align: right;
        opacity: 0;
        transition: 250ms;
      }
      span {
        display: block;
        background: rgba(255, 255, 255, 0.25);
        height: 12px;
        width: 12px;
        border-radius: 50%;
        transition: 250ms;
        transform: scale(0.66);
      }
      &:hover span {
        transform: scale(1);
      }
      &:hover p {
        opacity: 1;
        transform: translateX(-100%) translateY(-55%);
      }
      &.active span {
        background: rgba(255, 255, 255, 1);
        transform: scale(1);
      }
      &.active p {
        -webkit-animation: NAME-YOUR-ANIMATION 2s; /* Safari 4+ */
        -moz-animation: NAME-YOUR-ANIMATION 2s; /* Fx 5+ */
        -o-animation: NAME-YOUR-ANIMATION 2s; /* Opera 12+ */
        animation: NAME-YOUR-ANIMATION 2s; /* IE 10+, Fx 29+ */

        opacity: 0;
        transform: translateX(-90%) translateY(-55%);
      }

      @-webkit-keyframes NAME-YOUR-ANIMATION {
        0% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
        50% {
          opacity: 1;
          transform: translateX(-100%) translateY(-55%);
        }
        100% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
      }
      @-moz-keyframes NAME-YOUR-ANIMATION {
        0% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
        50% {
          opacity: 1;
          transform: translateX(-100%) translateY(-55%);
        }
        100% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
      }
      @-o-keyframes NAME-YOUR-ANIMATION {
        0% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
        50% {
          opacity: 1;
          transform: translateX(-100%) translateY(-55%);
        }
        100% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
      }
      @keyframes NAME-YOUR-ANIMATION {
        0% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
        50% {
          opacity: 1;
          transform: translateX(-100%) translateY(-55%);
        }
        100% {
          opacity: 0;
          transform: translateX(-90%) translateY(-55%);
        }
      }
    }
  }
  @media ${breakpoints.laptop} {
    display: block;
  }
`;
