import React, { useEffect } from "react";

import { graphql } from "gatsby";

import FCard from "components/fCard";
import TeamCards from "components/teamCards";

const Homepage = (props) => {
  //PROPS
  const {
    dimensions,
    data: {
      page: {
        homepage: { slides, events, videoIntro, imageIntro }
      }
    },
    setColor
  } = props;
  //PROPS

  //USEEFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, [dimensions]);
  //USEEFFECTS

  return (
    <>
      <FCard
        data={videoIntro}
        image={imageIntro}
        events={events}
        dimensions={dimensions}
      />
      <TeamCards data={slides} dimensions={dimensions} />
    </>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      homepage {
        slides {
          title
          text
          backgroundColor
          mobileImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
        }
        events {
          date
          text
          link {
            url
            title
            target
          }
        }
        videoIntro {
          mediaItemUrl
        }
        imageIntro {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;

export default Homepage;
