import React, { useEffect } from "react";
import { Wrapper } from "./Events.styles";

const Events = (props) => {
  //PROPS
  const { events } = props;
  //PROPS

  //EFFECTS
  useEffect(() => {
    if (window.navigator.userAgent.indexOf("Mac") != -1) {
      document
        .querySelectorAll(".event")
        .forEach((e) => e.classList.add("event-mac"));
    }
  }, []);
  //EFFECTS

  return (
    <Wrapper>
      {events.map((event, index) => {
        let splited = event.date.split(/([0-9]+)/).filter(Boolean);
        return (
          <li key={index} className="event">
            <div className="event-date">
              <span>{splited[0]}</span>
              <span className="small">{splited[1]}</span>
            </div>

            <div className="event-text">
              <p>{event.text}</p>
            </div>
          </li>
        );
      })}
    </Wrapper>
  );
};

export default Events;
