import styled from "styled-components";
import { green } from "theme/colors";
import { p_alternative } from "theme/typography";
import { breakpoints } from "utils/mixins";

export const Wrapper = styled.ul`
  position: absolute;
  display: none;
  top: 20%;
  right: 0;
  .event {
    position: relative;
    transition: 250ms;
    margin: 0;
    transform: translateX(calc(100% - 16px));
    &-date {
      transform: translateX(-100%);
      position: absolute;
      background-color: white;
      height: 60px;
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 20px;
      left: -14px;
      span {
        display: block;
        ${p_alternative}
        font-size: 30px;
        line-height: 0.8;
        transform: translateY(5px);
        color: ${green.default};
        margin: 0;
        padding: 0;
        &.small {
          ${p_alternative}
          font-size: 22px;
          line-height: 22px;
        }
      }
      &:hover .event-text {
        transform: translateX(-100%);
      }
      @media (min-width: 1400px) {
        height: 80px;
        width: 120px;
        span {
          font-size: 38px;
          &.small {
            ${p_alternative}
            font-size: 22px;
          }
        }
      }
    }
    &-text {
      transition: 400ms;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: auto;
      max-width: 350px;
      min-width: 160px;
      padding: 0 35px 0 20px;
      background-color: white;
      position: relative;
      right: 0;
      top: 0;
      transform: translateX(100%);
      p {
        width: auto;
        margin: 0;
        color: ${green.default};
        line-height: 1;
        font-size: 13px;
      }
      @media (min-width: 1400px) {
        height: 80px;
        line-height: inherit;
        font-size: inherit;
      }
    }
    &.event-mac {
      transform: translateX(100%);
    }
    &:hover .event-text,
    &-date:hover .event-text {
      transform: translateX(-100%);
    }
  }
  @media ${breakpoints.laptop} {
    display: block;
  }
`;
