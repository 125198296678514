import React from "react";
import { Link } from "react-scroll";

import { Wrapper } from "./Dots.styles";

const Dots = (props) => {
  //PROPS
  const { slide, setSlide, data } = props;
  //PROPS

  return (
    <Wrapper>
      {data.map((elem, index) => {
        return (
          <li key={index}>
            <Link
              to={"id" + index}
              spy={true}
              smooth={true}
              className={index === slide ? "active" : ""}
            >
              <p>{elem.title}</p>
              <span></span>
            </Link>
          </li>
        );
      })}
    </Wrapper>
  );
};

export default Dots;
